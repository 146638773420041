import * as React from 'react';

const KiLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120.456 299.766"
    style={{
      enableBackground: 'new 0 0 120.456 299.766',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        stroke: '#unset',
        strokeWidth: 8,
        strokeMiterlimit: 10,
      }}
      d="M115.942 294.53c-17.15 1.62-30.86-1.73-41.14-10.06-10.28-8.33-16.22-20.94-17.82-37.8l-5.4-57c-.64-6.77-3.2-11.81-7.67-15.13-4.48-3.31-10.47-4.62-17.98-3.91l-2.55-26.97c7.51-.71 13.15-3.11 16.92-7.21 3.77-4.1 5.34-9.53 4.7-16.3l-5.39-56.86c-1.59-16.87 1.87-30.38 10.4-40.54 8.53-10.16 21.37-16.05 38.52-17.68l2.55 26.98c-8.15.77-14.27 3.36-18.34 7.76-4.07 4.41-5.76 10.28-5.07 17.6l5.7 60.2c1.41 14.86-1.78 26.86-9.57 36.02 9.4 7.81 14.83 19.44 16.29 34.88l5.53 58.39c.69 7.32 3.46 12.79 8.29 16.4 4.83 3.61 11.32 5.03 19.48 4.26l2.55 26.97z"
    />
    <path d="M0 251.255V97.643c0-17.108 13.869-30.977 30.977-30.977v153.612c0 17.109-13.869 30.977-30.977 30.977z" />
  </svg>
);

export default KiLogo;
